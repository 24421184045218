import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Exit from './assets/Exit.png'
import './Quizfriendgame.css';
import { createClient } from '@supabase/supabase-js';

const Quizfriendgame = () => {
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [isNextClicked, setIsNextClicked] = useState(false);
  const [formData2, setFormData] = useState(() => {
    const storedData = localStorage.getItem('formData2');
    return storedData ? JSON.parse(storedData) : {
      food: "",
      color: "",
      place: "",
      music: "",
      movie: "",
      game: "",
      socialmedia: "",
      hobby: "",
      feeling: "",
      memory: "",
    };
  });

  const location = useLocation();
  const navigate = useNavigate();
  const { region, usersname } = location.state || {};

  const questions = [
    { question: " What is my favorite food?", options: ["Pizza", "Sushi", "Pasta", "Burgers"], field: 'food' },
    { question: "What is my favorite color?", options: ["Red", "black", "blue", "white"], field: 'color' },
    { question: "What’s my dream vacation destination?", options: ["Paris", "Maldives", "New York", "Tokyo"], field: 'place' },
    { question: "What type of music do I like the most?", options: ["Pop", "Rock", "Jazz", "Classical"], field: 'music' },
    { question: "What is my go-to movie genre?", options: ["Horror", "Love", "Action", "Comedy"], field: 'movie' },
    { question: "Which sport do I enjoy the most (watching or playing)?", options: ["Cricket", "Tennis", "Football", "Car racing"], field: 'game' },
    { question: "Which is my most used social media platform?", options: ["instagram", "Youtube", "WhatsApp","Facebook"], field: 'socialmedia' },
    { question: "What is my all-time favorite hobby?", options: ["Reading", "Travelling", "Cooking", "Gaming"], field: 'hobby' },
    { question: "What type of movies do I cry the most watching?", options: ["Sad dramas", "Romantic movies", "Historical tragedies", "Inspirational stories"], field: 'feeling' },
    { question: "Which is my favorite childhood memory?", options: ["Playing with friends", "Family trips", "School days", "Winning a competition"], field: 'memory' },
  ];


  const handleChange = (value) => {
    const currentField = questions[currentQuestionIndex].field;
    const updatedFormData = { ...formData2, [currentField]: value };
    setFormData(updatedFormData);
    setSelectedOption(value);
    localStorage.setItem('formData2', JSON.stringify(updatedFormData));
  };

  const handleNext = () => {
    const currentField = questions[currentQuestionIndex].field;
    if (!formData2[currentField]) {
      alert("Please select an option");
      return;
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedOption("");
    } else {
      postScoreToDatabase();
    }
  };

  const postScoreToDatabase = async () => {
        const SUPABASE_URL = 'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one/rest/v1/frienddata';
        const SUPABASE_ANON_KEY = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE';
        const SUPABASE_BEARER_KEY = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoic2VydmljZV9yb2xlIn0.lZoAKeF7q8Nxnp9Of82syKf3N2iJy-fiXWXByzOaHKE';
const headers = {
            'Content-Type': 'application/json',
            'apikey': SUPABASE_ANON_KEY,
            'Authorization': SUPABASE_BEARER_KEY,
        };

    try {
        const response = await fetch(SUPABASE_URL, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                username: usersname,
                region: region,
                ...formData2, // Post the entire form data
            }),
        });

        if (response.ok) {
            console.log('Data posted successfully!');
            setIsQuizCompleted(true); // Mark the quiz as completed
            await postCoins(); // Post coins after quiz completion
        } 
        else {
            const error = await response.json();
            console.error('Error posting data:', error);
        }
    } catch (error) {
        console.error('Error posting data:', error);
    }
};

//POSTING COINS IF USER SUBMITTED SUCCESSFULLY

const supabase = createClient(
    'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
  );

const [username, setUsername] = useState('');
useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
        setUsername(storedUsername);
    } else {
        console.warn('Username not found in localStorage');
    }
}, []);

const postCoins = async () => {
    if (!username) {
        console.error('Username not set. Cannot post coins.');
        return;
    }

  

    try {
        // Fetch current coins for the user
        const { data: userData, error: fetchError } = await supabase
            .from('usersdata')
            .select('coins')
            .eq('username', username)
            .single();

        if (fetchError || !userData) {
            console.error('Error fetching user data:', fetchError);
            return;
        }

        // Add 100 coins to the user's total coins
        const updatedCoins = userData.coins + 100; // Assuming 100 coins are added

        // POST new coin data to the database
        const { error: postError } = await supabase
            .from('usersdata')
            .update({ coins: updatedCoins })
            .eq('username', username);

        if (postError) {
            console.error('Error posting coins:', postError);
        } else {
            console.log(`Coins posted successfully for ${username}: +100 coins`);
        }
    } catch (error) {
        console.error('Error during coin posting:', error);
    }
};


if (isQuizCompleted) {
    const handleShareOnTwitter = () => {
        const tweetText = `I just completed a quiz! 🎉 Congrats to me 😍! #QuizMaster #FunTime`;
        const quizURL = "https://example.com/myquiz"; // Replace with your quiz URL
        const twitterIntentURL = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}&url=${encodeURIComponent(quizURL)}`;
        
        window.open(twitterIntentURL, "_blank");
    };

    return (
           <div className="quiz-game-page">
           <div className="quiz-game-header">
             <button className="exit-button" onClick={() => navigate('/')}>
              <img src={Exit} className='exit-png'/>
             </button>
             </div>
           <div className="text-center col-md-6 congrats">
               <h1>😍 Congrats {usersname}! Your Quiz is Ready!</h1>
               <h1>Share your results with friends!</h1>
   
   
               <div className='row'>
               <div className='col-4'>
                   <img
                       src={require('./assets/X.png')}
                       className="x-icon"
                       alt="Close"
                       onClick={handleShareOnTwitter}
                   />
               </div>
   
               <div className='col-4'>
                   <img
                       src={require('./assets/telegram.png')}
                       className="x-icon"
                       alt="Close"
                       onClick={handleShareOnTwitter}
                   />
               </div>
               <div className='col-4'>
                   <img
                       src={require('./assets/instagram.png')}
                       className="x-icon"
                       alt="Close"
                       onClick={handleShareOnTwitter}
                   />
               </div>
   
               </div>
           </div>
       
       </div>
           );
    }

  const currentQuestion = questions[currentQuestionIndex];
  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div className="quiz-game-page">
      <div className="quiz-game-header">
        <button className="exit-button" onClick={() => navigate('/')}>
          <img src={Exit} className='exit-png'/>
        </button>
        <div className="quiz-progress">
          Question {currentQuestionIndex + 1}/{questions.length}
        </div>
      </div>
      <div className="quiz-game-content">
        <div className="question-card">
          <h2 className="question-text">{currentQuestion.question}</h2>

          <div className="options-grid">
            {currentQuestion.options.map((option, index) => (
              <button
                key={index}
                className={`option-button ${selectedOption === option ? 'selected' : ''}`}
                onClick={() => handleChange(option)}
              >
                {option}
              </button>
            ))}
          </div>

          <button
            className="navigation-button"
            onClick={handleNext}
          >
            {isLastQuestion ? 'Submit Quiz' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quizfriendgame;
