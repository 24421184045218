import React, { useState, useEffect } from 'react'; // Ensure useEffect is imported
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import 'bootstrap/dist/css/bootstrap.min.css';
import Exit from './assets/Exit.png'
import './Quizusermother.css'

const Quizusermother = () => {
  const [region, setRegion] = useState('');
  const [usersname, setUsersname] = useState('');
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleClick = () => {
    navigate('/motherquiz', { state: { region, usersname } });
  };

  return (
    
    <div className='quizpager'>
     <button className="exit-button" onClick={() => navigate('/games')}>
               <img src={Exit} className='exit-png'/>
             </button>
        <div className="container quiz-content">
          <div className="row justify-content-center w-100">
          
            <div className='col-md-12 text-center'>
           
              <h1 className='user-header'>How Well Do Your Friends Know You?</h1>
              
            <h5 className='user-inst'>INSTRUCTIONS</h5>
            <ul className='user-rules'>
              <li>Choose your region.</li>
              <li>Enter your name.</li>
              <li>Answer any 10 Questions about yourself.</li>
              <li>Your quiz-link will be ready.</li>
              <li>Share your quiz-link with your friends.</li>
              <li>Your friends will try to guess the right answers.</li>
              <li>Check the score of your friends at your quiz-link!</li>
            </ul>
               
            {/* Region Selection */}
            <div className='col-md-12 text-center user-region'>
            <div>
            <label htmlFor="region-select" className='regular-head'>
                Select your region
              </label>
            </div>
              <select
                id="region-select"
                className='region-select'
                value={region}
                onChange={(e) => setRegion(e.target.value)} // Update region state
              >
                <option value="">Select</option>
                <option value="India">India</option>
                <option value="USA">USA</option>
                <option value="China">China</option>
                <option value="UK">UK</option>
                <option value="Australia">Australia</option>
                <option value="Europe">Europe</option>
              </select>
            </div>

            {/* Username Input */}
            <div className='col-md-12 text-center name'>
            <div>
            <label htmlFor="region-select" className='regular-head2'>
                Please enter username
              </label>
            </div>
              <input
                type='text'
                placeholder='Enter your name eg: Joey'
                className='input-name'
                value={usersname}
                onChange={(e) => setUsersname(e.target.value)} // Update username state
              />
            </div>

            {/* Submit Button */}
            <div className='col-md-12 text-center user-btn'>
              <button className='btn-submit' onClick={handleClick}>
                Submit
              </button>
            </div>
          </div>
        </div>
        </div>
    </div>
  );
};

export default Quizusermother;