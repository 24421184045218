import React, { useEffect } from 'react';

const TelegramHeaderColor = () => {
  useEffect(() => {
    // Function to set Telegram header color
    const setTelegramHeaderColor = () => {
      if (window.Telegram && window.Telegram.WebApp) {
        // Set the header color to black
        window.Telegram.WebApp.setHeaderColor('bg_color', '#242037');
        window.Telegram.WebApp.expand(); // Expand the WebApp for better UX
      } else {
        // Log a warning in development mode if it's not Telegram
        if (process.env.NODE_ENV !== 'production') {
          console.warn('Telegram WebApp is not available. Make sure you are running this in Telegram.');
        }
      }
    };

    // Call the function on component mount
    setTelegramHeaderColor();
  }, []); // Empty array ensures it runs only once on mount

  return (
    <div>
    </div>
  );
};

export default TelegramHeaderColor;
