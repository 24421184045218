import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Exit from './assets/Exit.png';
import './MemoryGame.css';

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedPairs, setMatchedPairs] = useState([]);
  const [currentPlayer, setCurrentPlayer] = useState(1);
  const [scores, setScores] = useState({ player1: 0, player2: 0 });
  const [isLocked, setIsLocked] = useState(false);
  const [showPopup, setShowPopup] = useState({ show: false, message: '' }); // Updated state for the popup
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const cardImages = [
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼',
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼'
  ];

  // Initialize game
  useEffect(() => {
    initializeGame();
  }, []);

  const initializeGame = () => {
    const shuffledCards = cardImages
      .sort(() => Math.random() - 0.5)
      .map((emoji, index) => ({
        id: index,
        emoji: emoji,
        isFlipped: false,
        isMatched: false
      }));
    setCards(shuffledCards);
    setFlippedCards([]);
    setMatchedPairs([]);
    setScores({ player1: 0, player2: 0 });
    setCurrentPlayer(1);
    setShowPopup({ show: false, message: '' }); // Reset popup state
  };

  const handleCardClick = (clickedCard) => {
    if (isLocked || showPopup.show) return;
    if (flippedCards.length === 2) return;
    if (flippedCards.includes(clickedCard.id)) return;
    if (matchedPairs.includes(clickedCard.id)) return;

    // Flip the card
    const newCards = cards.map(card =>
      card.id === clickedCard.id ? { ...card, isFlipped: true } : card
    );
    setCards(newCards);
    setFlippedCards([...flippedCards, clickedCard.id]);

    // Check for match when second card is flipped
    if (flippedCards.length === 1) {
      const firstCard = cards.find(card => card.id === flippedCards[0]);

      if (firstCard.emoji === clickedCard.emoji) {
        // Match found
        setMatchedPairs([...matchedPairs, firstCard.id, clickedCard.id]);
        setScores(prev => ({
          ...prev,
          [`player${currentPlayer}`]: prev[`player${currentPlayer}`] + 1
        }));
        setFlippedCards([]);
      } else {
        // No match
        setIsLocked(true);
        setTimeout(() => {
          setCards(cards.map(card =>
            flippedCards.includes(card.id) || card.id === clickedCard.id
              ? { ...card, isFlipped: false }
              : card
          ));
          setFlippedCards([]);
          setCurrentPlayer(currentPlayer === 1 ? 2 : 1);
          setIsLocked(false);
        }, 1000);
      }
    }
  };

  const checkGameEnd = () => {
    if (matchedPairs.length > 0 && matchedPairs.length === cards.length) {
      let message = '';
      if (scores.player1 > scores.player2) {
        message = `🎉 Player 1 Wins! 🎉\nScore: ${scores.player1} - ${scores.player2}`;
      } else if (scores.player2 > scores.player1) {
        message = `🎉 Player 2 Wins! 🎉\nScore: ${scores.player2} - ${scores.player1}`;
      } else {
        message = `🤝 It's a Tie! 🤝\nScore: ${scores.player1} - ${scores.player2}`;
      }
      setShowPopup({ show: true, message }); // Set popup with message
    }
  };

  useEffect(() => {
    checkGameEnd();
  }, [matchedPairs]);

  const resetGame = () => {
    initializeGame();
  };

  return (
    <div className="memory-game">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png' alt="Exit" />
      </button>
      <div className="game-header">
        <h1>Memory Game</h1>

        <div className="player-scores">
          <div className={`player-card ${currentPlayer === 1 ? 'active' : ''}`}>
            <div className="player-avatar">P1</div>
            <div className="player-info">
              <h3>Player 1</h3>
              <div className="score">{scores.player1}</div>
            </div>
          </div>

          <div className="vs-indicator">VS</div>

          <div className={`player-card ${currentPlayer === 2 ? 'active' : ''}`}>
            <div className="player-avatar">P2</div>
            <div className="player-info">
              <h3>Player 2</h3>
              <div className="score">{scores.player2}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="card-grid">
        {cards.map(card => (
          <div
            key={card.id}
            className={`card ${card.isFlipped || matchedPairs.includes(card.id) ? 'flipped' : ''}`}
            onClick={() => handleCardClick(card)}
          >
            <div className="card-inner">
              <div className="card-front">❓</div>
              <div className="card-back">{card.emoji}</div>
            </div>
          </div>
        ))}
      </div>
      <div className='reset-container'>
        <button className="reset-button" onClick={resetGame}>
          New Game
        </button>
      </div>

      {showPopup.show && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h2>Game Over!</h2>
            <h5>Congratulations</h5>
            <p className='message-memory'>{showPopup.message}</p>
            <div className="final-scores">
              <div className="final-score-card">
                <div className="player-avatar">P1</div>
                <div className="final-score">{scores.player1}</div>
              </div>
              <div className="final-score-card">
                <div className="player-avatar">P2</div>
                <div className="final-score">{scores.player2}</div>
              </div>
            </div>
            <button onClick={resetGame}>Play Again</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MemoryGame;