import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./EmojiHuntGame.css";
import Exit from './assets/Exit.png'

const EmojiHuntGame = () => {
  const [grid, setGrid] = useState([]);
  const [targetEmoji, setTargetEmoji] = useState("");
  const [message, setMessage] = useState("Find the target emoji!");
  const [chances, setChances] = useState(3);
  const [gameTime, setGameTime] = useState(0);
  const [gameActive, setGameActive] = useState(true);
  const [revealed, setRevealed] = useState(Array(16).fill(false));
  const emojis = ["😀", "🎉", "🍎", "💖", "🐱", "⚽", "🌟", "🍕", "🚗", "😎"];
  const navigate = useNavigate();

  useEffect(() => {
    generateGrid();
    setGameTime(0);
    setMessage("Find the target emoji!");
    setChances(3);
    setGameActive(true);
    setRevealed(Array(16).fill(false));

    const timer = setInterval(() => {
      setGameTime((time) => time + 1);
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const generateGrid = () => {
    const randomGrid = Array(16) 
      .fill(null)
      .map(() => emojis[Math.floor(Math.random() * emojis.length)]);
    const randomTarget = randomGrid[Math.floor(Math.random() * 16)];
    setTargetEmoji(randomTarget);
    setGrid(randomGrid);
  };

  const handleEmojiClick = (emoji, index) => {
    if (!gameActive || revealed[index]) return;

    const newRevealed = [...revealed];
    newRevealed[index] = true;
    setRevealed(newRevealed);

    if (emoji === targetEmoji) {
      setMessage("🎉 You found it! Well done!");
      setGameActive(false);
    } else {
      setChances((prev) => prev - 1);
      setMessage("❌ Not this one! Try again!");
      if (chances - 1 === 0) {
        setMessage("💀 Game Over! Try again.");
        setGameActive(false);
      }
    }
  };

  const handleRestart = () => {
    generateGrid();
    setGameTime(0);
    setChances(3);
    setMessage("Find the target emoji!");
    setGameActive(true);
    setRevealed(Array(16).fill(false));
  };

  return (
    <div className="game-background">
     
        <img src={Exit} className='exit-png' onClick={() => navigate('/games')}/>
      <div className="game-box">
        <h1>Emoji Hunt</h1>
        <p>
          Target Emoji: <span className="target">{targetEmoji}</span>
        </p>
        <div className="emoji-grid">
          {grid.map((emoji, index) => (
            <button
              key={index}
              className={`emoji-button ${revealed[index] ? "revealed" : ""}`}
              onClick={() => handleEmojiClick(emoji, index)}
            >
              {revealed[index] ? emoji : "❓"}
            </button>
          ))}
        </div>
        <p className="message">{message}</p>
        <p>Lives Remaining: {chances}</p>
        
        {!gameActive && (
          <button className="restart-button" onClick={handleRestart}>
            Restart Game
          </button>
        )}
      </div>
    </div>
  );
};

export default EmojiHuntGame;
