import React, { useState, useEffect, useCallback } from 'react';
import Exit from './assets/Exit.png';
import { useNavigate } from 'react-router-dom';
import './WhackAMole.css';

const WhackAMole = () => {
  const [score, setScore] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(30);
  const [activeMole, setActiveMole] = useState(null);
  const [highScore, setHighScore] = useState(
    localStorage.getItem('whackAMoleHighScore') || 0
  );
  const navigate = useNavigate();

  const MOLE_INTERVAL = 1000; // Time each mole appears (ms)
  const TOTAL_HOLES = 9;

  // Generate random mole position
  const generateMolePosition = useCallback(() => {
    if (!gameStarted) return;
    const randomHole = Math.floor(Math.random() * TOTAL_HOLES);
    setActiveMole(randomHole);

    // Automatically hide mole after interval
    setTimeout(() => {
      if (activeMole === randomHole) {
        setActiveMole(null);
      }
    }, MOLE_INTERVAL);
  }, [gameStarted, activeMole]);

  // Handle both click and touch events
  const handleMoleClick = (index, e) => {
    e.preventDefault(); // Prevent double-firing on touch devices
    if (gameStarted && index === activeMole) {
      setScore(prevScore => prevScore + 1);
      setActiveMole(null);
      generateMolePosition();
    }
  };

  // Start game
  const startGame = () => {
    setScore(0);
    setTimeLeft(30);
    setGameStarted(true);
    setActiveMole(null);
    generateMolePosition();
  };

  // Game timer
  useEffect(() => {
    let timer;
    if (gameStarted && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setGameStarted(false);
      if (score > highScore) {
        setHighScore(score);
        localStorage.setItem('whackAMoleHighScore', score);
      }
    }
    return () => clearInterval(timer);
  }, [gameStarted, timeLeft, score, highScore]);

  // Mole generation loop
  useEffect(() => {
    let moleTimer;
    if (gameStarted) {
      moleTimer = setInterval(generateMolePosition, MOLE_INTERVAL);
    }
    return () => clearInterval(moleTimer);
  }, [gameStarted, generateMolePosition]);

  return (
    <div className="whack-a-mole">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} alt="Exit" className="exit-png" />
      </button>
      <div className="game-info">
        <h1>Whack-a-Mole!</h1>
        <div className="scores">
          <div className="score-item">
            <p>Score: {score}</p>
          </div>
          <div className="score-item">
            <p>High Score: {highScore}</p>
          </div>
          <div className="score-item">
            <p>Time: {timeLeft}s</p>
          </div>
        </div>
        {!gameStarted && (
          <button 
            className="start-button" 
            onClick={startGame}
            onTouchStart={startGame}
          >
            {timeLeft === 30 ? 'Start Game' : 'Play Again'}
          </button>
        )}
      </div>

      <div className="game-board">
        {Array(TOTAL_HOLES).fill(null).map((_, index) => (
          <div 
            key={index} 
            className={`hole ${activeMole === index ? 'active' : ''}`}
            onClick={(e) => handleMoleClick(index, e)}
            onTouchStart={(e) => handleMoleClick(index, e)}
          >
            <div className="mole"></div>
          </div>
        ))}
      </div>

      {timeLeft === 0 && (
        <div className="game-over">
          <h2>Game Over!</h2>
          <p>Final Score: {score}</p>
          {score > highScore && <p>New High Score! 🎉</p>}
        </div>
      )}
    </div>
  );
};

export default WhackAMole; 