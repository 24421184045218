import React, { useState, useEffect } from 'react';
import './RewardsPage.css';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import Rewards from './assets/Rewards-p.png';
import Rewardsgreen from './assets/Rewards-g.png';
import telegram from './assets/telegram.png';
import x from './assets/X.png';
import instagram from './assets/instagram.png';
import Navbar from './Navbar';
import Rewardsicon from './assets/Rewards_icon.png'
// Initialize Supabase client
const supabase = createClient(
  'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
);

function RewardsPage({ onExit }) {
  const [rewards, setRewards] = useState([
    {
      id: 1,
      platform: 'Daily Login',
      icon: '',
      coins: 100,
      claimed: false,
      description: 'Login daily to claim your reward',
      supabaseField: 'loginDaily',
    },
    {
      id: 2,
      platform: 'Telegram',
      icon: '',
      coins: 500,
      claimed: false,
      link: 'https://t.me/yourgroup',
      supabaseField: 'telegram',
    },
    {
      id: 3,
      platform: 'X (Twitter)',
      icon: '',
      coins: 300,
      claimed: false,
      link: 'https://twitter.com/youraccount',
      supabaseField: 'x',
    },
    {
      id: 4,
      platform: 'Instagram',
      icon: '',
      coins: 400,
      claimed: false,
      link: 'https://instagram.com/youraccount',
      supabaseField: 'instagram',
    },
  ]);
  const [username, setUsername] = useState('');

  // Fetch username from localStorage on mount
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    } else {
      console.warn('Username not found in localStorage');
    }
  }, []);

  // Fetch user data from Supabase on mount
  useEffect(() => {
    const fetchUserData = async () => {
      if (!username) return;

      try {
        const { data, error } = await supabase
          .from('usersdata')
          .select('telegram, instagram, x, loginDaily, coins')
          .eq('username', username)
          .single();

        if (error) {
          console.error('Error fetching user data:', error);
          return;
        }

        // Update rewards claimed state
        setRewards((prevRewards) =>
          prevRewards.map((reward) => ({
            ...reward,
            claimed:
              reward.supabaseField === 'loginDaily'
                ? data.loginDaily &&
                  new Date() - new Date(data.loginDaily) < 24 * 60 * 60 * 1000
                : data[reward.supabaseField] === 'Completed',
          }))
        );
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [username]);

  const handleClaim = async (id) => {
    const reward = rewards.find((r) => r.id === id);
    if (!reward || reward.claimed) {
      console.warn(`${reward.platform} reward already claimed.`);
      return;
    }

    try {
      // Fetch current coins for the user
      const { data: userData, error: fetchError } = await supabase
        .from('usersdata')
        .select('coins')
        .eq('username', username)
        .single();

      if (fetchError || !userData) {
        console.error('Error fetching user coins:', fetchError);
        return;
      }

      // Add coins for the claimed reward
      const updatedCoins = userData.coins + reward.coins;

      // Update reward status and coins in the database
      const updates = {
        [reward.supabaseField]:
          reward.supabaseField === 'loginDaily'
            ? new Date().toISOString()
            : 'Completed',
        coins: updatedCoins,
      };

      const { error: updateError } = await supabase
        .from('usersdata')
        .update(updates)
        .eq('username', username);

      if (updateError) {
        console.error('Error updating reward status and coins:', updateError);
        return;
      }

      console.log(
        `Reward claimed: ${reward.platform}, Coins updated: +${reward.coins}`
      );

      // Update local state
      setRewards((prevRewards) =>
        prevRewards.map((r) =>
          r.id === id ? { ...r, claimed: true } : r
        )
      );
    } catch (error) {
      console.error('Error during claim process:', error);
    }
  };

  return (
    <div className=" rewards-pager ">
    <h1 className='Reward-header'>Rewards</h1>
      <div className='reward-icon-header' >
      <img src={Rewardsicon} alt="Rewards" className="rewards-img"/>
      </div>
      <div className='header-task'>
            <h6 className=' task-list'> <b>Task List</b> </h6>
        </div>
   
        <div className='row w-100'>
          <div className='col-1'></div>
          <div className='col-11 rd-bg'> 
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center gap-3'>
                <img src={telegram} alt="Telegram" className='telegram-img' style={{width: '40px'}}/>
                <span className='text-telegram text-white'>Join our Telegram channel</span>
              </div>
              <span className='text-white' style={{fontSize: '20px'}}>➜</span>
            </div>
          </div>
          
        
       
          <div className='col-1'></div>
          <div className='col-11 rd-bg'> 
          <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center gap-3'>
                <img src={instagram} alt="Telegram" className='telegram-img' style={{width: '40px'}}/>
                <span className='text-telegram text-white'>Join our Instagram account</span>
              </div>
              <span className='text-white' style={{fontSize: '20px'}}>➜</span>
            </div>
          </div>
         
        
        
          <div className='col-1'></div>
          <div className='col-11 rd-bg'> 
          <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center gap-3'>
                <img src={x} alt="Telegram" className='telegram-img2' style={{width: '40px'}}/>
                <span className='text-telegram text-white'>Join our X account</span>
              </div>
              <span className='text-white' style={{fontSize: '20px'}}>➜</span>
            </div>
          </div>
          
          </div>
          <Navbar activeLink="/rewards" />
    </div>
  );
}
export default RewardsPage;
