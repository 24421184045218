import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Exit from './assets/Exit.png';
import "./ColorMatch.css";

const ColorMatch = () => {
  const [tiles, setTiles] = useState([]);
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState(1);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isPlaying, setIsPlaying] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const navigate = useNavigate();

  const colors = [
    "#FF5733",
    "#33FF57",
    "#3357FF", // Level 1: 3 colors
    "#FF33F5",
    "#33FFF5",
    "#FFF533", // Level 2: +3 colors
    "#FF8333",
    "#33FF83",
    "#8333FF", // Level 3: +3 colors
    "#FF3383",
    "#33FFFF",
    "#FFFF33", // Level 4: +3 colors
  ];

  const initializeGame = () => {
    const colorsPerLevel = 3;
    const numPairs = Math.min(colorsPerLevel * level, colors.length / 2);
    const gameColors = colors.slice(0, numPairs);
    createTiles(gameColors);
    setTimeLeft(60);
    setIsPlaying(true);
    setGameOver(false);
  };

  const createTiles = (gameColors) => {
    const pairs = [...gameColors, ...gameColors];
    const shuffledTiles = pairs
      .sort(() => Math.random() - 0.5)
      .map((color, index) => ({
        id: index,
        color,
        isFlipped: false,
        isMatched: false,
      }));
    setTiles(shuffledTiles);
    setSelectedTiles([]);
  };

  useEffect(() => {
    let timer;
    if (isPlaying && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setGameOver(true);
      setIsPlaying(false);
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeLeft]);

  useEffect(() => {
    if (tiles.length > 0 && tiles.every((tile) => tile.isMatched)) {
      if (level >= Math.floor(colors.length / 3)) {
        setGameOver(true);
        setIsPlaying(false);
      } else {
        setLevel((prev) => prev + 1);
        setScore((prev) => prev + timeLeft * 10);
        setTimeout(initializeGame, 1000);
      }
    }
  }, [tiles]);

  const handleTileClick = (clickedTile) => {
    if (!isPlaying || clickedTile.isMatched || selectedTiles.length >= 2) return;

    const newTiles = tiles.map((tile) =>
      tile.id === clickedTile.id ? { ...tile, isFlipped: true } : tile
    );
    setTiles(newTiles);

    const newSelected = [...selectedTiles, clickedTile];
    setSelectedTiles(newSelected);

    if (newSelected.length === 2) {
      if (newSelected[0].color === newSelected[1].color) {
        setTimeout(() => {
          setTiles((prev) =>
            prev.map((tile) =>
              newSelected.some((selected) => selected.id === tile.id)
                ? { ...tile, isMatched: true }
                : tile
            )
          );
          setSelectedTiles([]);
        }, 500);
        setScore((prev) => prev + 100);
      } else {
        setTimeout(() => {
          setTiles((prev) =>
            prev.map((tile) =>
              newSelected.some((selected) => selected.id === tile.id)
                ? { ...tile, isFlipped: false }
                : tile
            )
          );
          setSelectedTiles([]);
        }, 1000);
      }
    }
  };

  return (
    <div className="color-match">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png' alt="Exit" />
      </button>
      <div className="game-header">
        <div className="stats">
          <div className="stat">Level: {level}</div>
          <div className="stat">Score: {score}</div>
          <div className="stat">Time: {timeLeft}s</div>
        </div>
      </div>

      {!isPlaying && !gameOver && (
        <div className="start-screen">
          <h1>Color Match</h1>
          <button onClick={initializeGame}>Start Game</button>
        </div>
      )}

      {gameOver && (
        <div className="game-over">
          <h2>{level > 1 ? "Congratulations!" : "Game Over!"}</h2>
          <p>Final Score: {score}</p>
          <p>Levels Completed: {level - 1}</p>
          <button
            onClick={() => {
              setLevel(1);
              setScore(0);
              initializeGame();
            }}
          >
            Play Again
          </button>
        </div>
      )}

      <div className="tile-grid">
        {tiles.map((tile) => (
          <div
            key={tile.id}
            className={`tile ${tile.isFlipped || tile.isMatched ? "flipped" : ""}`}
            onClick={() => handleTileClick(tile)}
          >
            <div className="tile-inner">
              <div className="tile-front"></div>
              <div
                className="tile-back"
                style={{ backgroundColor: tile.color }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ColorMatch;
