import React from 'react';
import './Games.css';
import Bestfriend from './assets/bestfriend.png';
import Brother from './assets/brotherquiz.png';
import Friend from './assets/friendquiz.png';
import Mother from './assets/motherquiz.png';
import Sister from './assets/sisterquiz.png';
import Father from './assets/fatherquiz.png';
import tapgame from './assets/tapgame.png';
import games from './assets/Games.png';
import ball from './assets/tapball.png';
import Tictactoe from './assets/tictactoe.png';
import Quiz from './assets/Quiz_Button.png'
import Dice from './assets/Dicegame.png'
import Memory from './assets/Memorygame.png'
import ShapeShooter from './assets/shapeshooter.png';
import Colormatch from './assets/Colormatch1.png'
import Scramble from './assets/scramble.png'
import Number from './assets/numberguess.png'
import emoji from './assets/emojihunt.png'
import Rock from './assets/Rock.png'
import Calculate from './assets/Calculate.png'
import Mole from './assets/Mole.png'
import Mine from './assets/Mine.png'
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';

const Games = () => {
  const navigate = useNavigate()

  const handlebestfriendquiz = ()=>{
    navigate('/bestfriend')
  }
  const handlefriendquiz = ()=>{
    navigate('/friend')
  }
  const handlebrotherquiz = ()=>{
    navigate('/brother')
  }
  const handlesisterquiz = ()=>{  
    navigate('/sister')
  }
  const handlemotherquiz = ()=>{  
    navigate('/mother')
  }
  const handlefatherquiz = ()=>{  
    navigate('/father')
  }
  const handletapnumbergame = ()=>{  
    navigate('/tapnumbergame')
  }
  const handletictactoe = ()=>{  
    navigate('/tictactoe')
  }
  const handletapgame = ()=>{  
    navigate('/tapgame')
  }
  const handledicegame = ()=>{  
    navigate('/dice')
  }
  const handlememorygame = ()=>{  
    navigate('/memory')
  }
  const handleshapeshooter = ()=>{  
    navigate('/shooter')
  }
  const handlecolormatch = ()=>{  
    navigate('/colormatch')
  }
  const handlescrambleword = ()=>{  
    navigate('/scramble')
  }
  const handlenumberguess = ()=>{  
    navigate('/numberguess')
  }
  const handleemojihunt = ()=>{  
    navigate('/emojihunt')
  }
  const handleRockgame = ()=>{  
    navigate('/rock')
  }
  const handleCalculategame = ()=>{  
    navigate('/calculate')
  }
  const handleMolegame = ()=>{  
    navigate('/mole')
  }
  const handleMinegame = ()=>{  
    navigate('/mine')
  }

  return (
    <div className="games-page">
      <div className="games-header">
        <img src={Quiz} alt="Quiz" className="quiz-img" />
      </div>
      <div className="container ">
        <div className="row games-containing">
          <div className="col-6 col-md-6 ">
            <img src={Bestfriend} alt="Bestfriend" className="game-img" onClick={handlebestfriendquiz} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Friend} alt="Friend" className="game-img" onClick={handlefriendquiz}/>
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Brother} alt="Brother" className="game-img" onClick={handlebrotherquiz} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Sister} alt="Sister" className="game-img" onClick={handlesisterquiz} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Mother} alt="Mother" className="game-img" onClick={handlemotherquiz} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Father} alt="Father" className="game-img" onClick={handlefatherquiz} />
          </div>
          <div className='col-12'>
            <img src={games} alt="games.png" className="game-png" />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={tapgame} alt="tapgame" className="game-img2" onClick={handletapnumbergame} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Tictactoe} alt="tictactoe" className="game-img2" onClick={handletictactoe} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={ball} alt="tapball" className="game-img2" onClick={handletapgame} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Dice} alt="tapball" className="game-img2" onClick={handledicegame} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Memory} alt="tapball" className="game-img2" onClick={handlememorygame} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={ShapeShooter} alt="tapball" className="game-img2" onClick={handleshapeshooter} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Colormatch} alt="tapball" className="game-img2" onClick={handlecolormatch} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Scramble} alt="tapball" className="game-img2" onClick={handlescrambleword} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Number} alt="tapball" className="game-img2" onClick={handlenumberguess} />
          </div>
          <div className="col-6 col-md-6 ">
            <img src={emoji} alt="tapball" className="game-img2" onClick={handleemojihunt}/>
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Rock} alt="tapball" className="game-img2" onClick={handleRockgame}/>
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Calculate} alt="tapball" className="game-img2" onClick={handleCalculategame}/>
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Mole} alt="tapball" className="game-img2" onClick={handleMolegame}/>
          </div>
          <div className="col-6 col-md-6 ">
            <img src={Mine} alt="tapball" className="game-img2" onClick={handleMinegame}/>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default Games;
