import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import Exit from './assets/Exit.png';
import './Tictactoe.css';
import xicon from './assets/cross.png';
import Circle from './assets/circle.png';

let data = ["", "", "", "", "", "", "", "", ""];

const Tictactoe = () => {
  const [count, setCount] = useState(0);
  const [lock, setLock] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false); // Track if the game is over
  const [winner, setWinner] = useState(""); // Store the winner ("x", "o", or "tie")
  const titleRef = useRef(null);
  const navigate = useNavigate();

  const box1 = useRef(null);
  const box2 = useRef(null);
  const box3 = useRef(null);
  const box4 = useRef(null);
  const box5 = useRef(null);
  const box6 = useRef(null);
  const box7 = useRef(null);
  const box8 = useRef(null);
  const box9 = useRef(null);

  const boxRefs = [box1, box2, box3, box4, box5, box6, box7, box8, box9];

  useEffect(() => {
    // Automatically make system move if count is odd and not locked
    if (count % 2 !== 0 && !lock) {
      systemMove(); // Trigger the system move
    }
  }, [count, lock]);

  const toggle = (e, num) => {
    if (lock) return;
    if (count % 2 === 0) {
      e.target.innerHTML = `<img src='${xicon}'>`;
      data[num] = 'x';
      setCount(prev => prev + 1);
    } else {
      e.target.innerHTML = `<img src='${Circle}'>`;
      data[num] = 'o';
      setCount(prev => prev + 1);
    }
    checkWin();
  };

  const checkWin = () => {
    if (data[0] === data[1] && data[1] === data[2] && data[2] !== "") {
      won(data[2]);
    } else if (data[3] === data[4] && data[4] === data[5] && data[5] !== "") {
      won(data[5]);
    } else if (data[6] === data[7] && data[7] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[0] === data[3] && data[3] === data[6] && data[6] !== "") {
      won(data[6]);
    } else if (data[1] === data[4] && data[4] === data[7] && data[7] !== "") {
      won(data[7]);
    } else if (data[2] === data[5] && data[5] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[0] === data[4] && data[4] === data[8] && data[8] !== "") {
      won(data[8]);
    } else if (data[2] === data[4] && data[4] === data[6] && data[6] !== "") {
      won(data[6]);
    } else if (!data.includes("")) {
      tie();
    }
  };
  const won = (winner) => {
    setLock(true);
    setIsGameOver(true);
    setWinner(winner === "x" ? "Player (X)" : "System (O)");
  };
  const tie = () => {
    setLock(true);
    setIsGameOver(true);
    setWinner("It's a Tie!");
  };

  const systemMove = () => {
    const findWinningMove = (player) => {
      const winConditions = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
      ];

      for (let [a, b, c] of winConditions) {
        if (data[a] === player && data[b] === player && data[c] === "") return c;
        if (data[a] === player && data[c] === player && data[b] === "") return b;
        if (data[b] === player && data[c] === player && data[a] === "") return a;
      }
      return null; // No winning or blocking move found
    };

    // Check for a winning move first (as 'o') or block the player's win (as 'x')
    const winningMove = findWinningMove('o');
    const blockingMove = findWinningMove('x');

    // Choose the best move based on the priorities
    const move =
      winningMove !== null
        ? winningMove // Win if possible
        : blockingMove !== null
        ? blockingMove // Block the player's win
        : data.indexOf(""); // Pick any empty box if no immediate win/defense is needed

    data[move] = 'o';
    if (boxRefs[move] && boxRefs[move].current) {
      boxRefs[move].current.innerHTML = `<img src='${Circle}' alt='O Icon'>`;
    }
    setCount(prev => prev + 1);

    // Check if the system wins or it's a tie
    if (checkWin()) return;
    if (!data.includes("")) return tie();
    setLock(false); // Unlock the board for the player's turn
  };

  const reset = () => {
    setLock(false);
    setIsGameOver(false);
    setWinner("");
    data = ["", "", "", "", "", "", "", "", ""];
    titleRef.current.innerHTML = "Tic Tac Toe";
  
    // Reset the game board UI
    boxRefs.forEach((ref) => {
      if (ref.current) ref.current.innerHTML = "";
    });
  
    setCount(0);  // Set the count to 0, indicating it's Player X's turn
  };
  

  return (
    <div className="container-tictactoe">
    <button className="exit-button" onClick={() => navigate('/games')}>
      <img src={Exit} alt="Exit" className="exit-png" />
    </button>
    <div className="tictactoe-header">
      <h1 className="title" ref={titleRef}> TIC TAC TOE <span className="spantext"></span></h1>
    </div>

      {/* Game board */}
      <div className="board">
      
        <div className="row1">
          <div className="boxes" ref={box1} onClick={(e) => { toggle(e, 0); }}></div>
          <div className="boxes" ref={box2} onClick={(e) => { toggle(e, 1); }}></div>
          <div className="boxes" ref={box3} onClick={(e) => { toggle(e, 2); }}></div>
        </div>
        <div className="row2">
          <div className="boxes" ref={box4} onClick={(e) => { toggle(e, 3); }}></div>
          <div className="boxes" ref={box5} onClick={(e) => { toggle(e, 4); }}></div>
          <div className="boxes" ref={box6} onClick={(e) => { toggle(e, 5); }}></div>
        </div>
        <div className="row3">
          <div className="boxes" ref={box7} onClick={(e) => { toggle(e, 6); }}></div>
          <div className="boxes" ref={box8} onClick={(e) => { toggle(e, 7); }}></div>
          <div className="boxes" ref={box9} onClick={(e) => { toggle(e, 8); }}></div>
        </div>
      </div>
      
      {/* Game Over Pop-up */}
      {isGameOver && (
        <div className="game-over-modal">
          <div className="modal-content">
            <h2>{winner}</h2>
            <button className="restart-btn" onClick={reset}>Restart Game</button>
          </div>
        </div>
      )}
      
      {/* Reset Button (if no pop-up is shown) */}
      <div className="tac-footer">
      {!isGameOver && <button className="reset-btn" onClick={reset}>Reset</button>}
      </div>
      
    </div>
  );
}

export default Tictactoe;
