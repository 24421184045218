import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DailyRewardsIcon from './assets/Daily_Rewards.png';
import RewardsIcon from './assets/Rewards.png';
import HomeIcon from './assets/Home.png';
import PlayIcon from './assets/Play.png';
import SettingsIcon from './assets/Settings.png';
import RectangleBar from './assets/Rectanglebar.png';
import Highlight from './assets/Highlight_Bar.png';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname); // Initialize with the current location
  
  const handleClick = (path) => {
    setActiveLink(path);
  };

  return (
    <nav className="navbar fixed-bottom">
      <div className="container">
        <div className="row justify-content-center align-items-center w-100 p-0 ">
          <div className="col-12 text-center p-0  position-relative">
            <img src={RectangleBar} alt="Rectangle Bar" className="rectangle-bar" />
            <div className="icons-container d-flex justify-content-around">
              <Link to="/dailyrewards" onClick={() => handleClick('/dailyrewards')}>
                <div className={`icon-container ${activeLink === '/dailyrewards' ? 'active' : ''}`}>
                  <img src={DailyRewardsIcon} alt="Daily Rewards" className="icon" />
                </div>
              </Link>
              <Link to="/rewards" onClick={() => handleClick('/rewards')}>
                <div className={`icon-container ${activeLink === '/rewards' ? 'active' : ''}`}>
                  <img src={RewardsIcon} alt="Rewards" className="icon" />
                </div>
              </Link>
              <Link to="/" onClick={() => handleClick('/')}>
                <div className={`icon-container ${activeLink === '/' ? 'active' : ''}`}>
                  <img src={HomeIcon} alt="Home" className="icon" />
                </div>
              </Link>
              <Link to="/games" onClick={() => handleClick('/games')}>
                <div className={`icon-container ${activeLink === '/games' ? 'active' : ''}`}>
                  <img src={PlayIcon} alt="Play" className="icon" />
                </div>
              </Link>
              <Link to="/settings" onClick={() => handleClick('/settings')}>
                <div className={`icon-container ${activeLink === '/settings' ? 'active' : ''}`}>
                  <img src={SettingsIcon} alt="Settings" className="icon" />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
