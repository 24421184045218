import React from 'react';

function Ball({ x, y, onClick }) {
  const handleInteraction = (e) => {
    e.preventDefault(); // Prevent default touch behavior
    onClick();
  };

  return (
    <div
      className="ball"
      style={{
        left: `${x}%`,
        top: `${y}px`
      }}
      onClick={handleInteraction}
      onTouchStart={handleInteraction}
    />
  );
}

export default Ball;