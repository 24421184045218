import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Ball from './components/Ball';
import Score from './components/Score';
import GameOver from './components/GameOver';
import { createNewBall } from './utils/gameUtils';
import Exit from './assets/Exit.png';
import './Tapgame.css';
import { createClient } from '@supabase/supabase-js';

// Supabase Client Initialization
const supabase = createClient(
  'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
);

function Tapgame() {
  const [balls, setBalls] = useState([]);
  const [score, setScore] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);
  const [gameOver, setGameOver] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');

  const createBall = useCallback(() => {
    if (isPlaying) {
      setBalls((prevBalls) => [...prevBalls, createNewBall()]);
    }
  }, [isPlaying]);

  const handleBallClick = (id) => {
    setScore((prevScore) => prevScore + 1);
    setBalls((prevBalls) => prevBalls.filter((ball) => ball.id !== id));
  };

  const handleRestart = () => {
    setBalls([]);
    setScore(0);
    setIsPlaying(true);
    setGameOver(false);
  };

  const postscore = async () => {
    try {
      // Fetch existing score from Supabase
      const { data, error: fetchError } = await supabase
        .from('usersdata')
        .select('tapballs')
        .eq('username', username)
        .single();

      // Handle fetch errors or missing data
      if (fetchError || !data) {
        console.error('Fetch Error:', fetchError?.message);
        return;
      }

      const existingScore = data.tapballs; // Assuming "tapballs" is the column name for score

      // Compare the current score with the existing score
      if (score > existingScore) {
        // If the current score is greater than the existing one, update Supabase
        const { error: updateError } = await supabase
          .from('usersdata')
          .update({ tapballs: score })
          .eq('username', username);

        if (updateError) {
          console.error('Update Error:', updateError.message);
        }
      }
    } catch (error) {
      console.error('Error posting score:', error);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const usernameParam = urlParams.get('username');
    if (usernameParam) {
      setUsername(usernameParam);
      localStorage.setItem('username', usernameParam);
    } else {
      const storedUsername = localStorage.getItem('username');
      if (storedUsername) {
        setUsername(storedUsername);
      }
    }
  }, []);

  useEffect(() => {
    const ballInterval = setInterval(createBall, 1000); // Creates a ball every second
    const gameLoop = setInterval(() => {
      setBalls((prevBalls) => {
        const updatedBalls = prevBalls.map((ball) => ({
          ...ball,
          y: ball.y + ball.speed,
        }));

        // Check if any ball has reached the bottom
        const screenHeight = window.innerHeight;
        if (updatedBalls.some((ball) => ball.y > screenHeight - 25)) {
          setIsPlaying(false); // End the game if any ball reaches the bottom
          setGameOver(true);
          return [];
        }

        return updatedBalls;
      });
    }, 16); // Updates the balls every 16 milliseconds

    return () => {
      clearInterval(ballInterval);
      clearInterval(gameLoop);
    };
  }, [createBall]);

  return (
    <div className="game-container">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className="exit-png" alt="Exit" />
      </button>
      <Score score={score} />
      {balls.map((ball) => (
        <Ball key={ball.id} x={ball.x} y={ball.y} onClick={() => handleBallClick(ball.id)} />
      ))}
      {!isPlaying && <GameOver score={score} postscore={postscore} onRestart={handleRestart} />}
    </div>
  );
}

export default Tapgame;
