import React, { useState, useEffect } from 'react';
import Exit from './assets/Exit.png';
import { useNavigate } from 'react-router-dom';
import './MineSweeper.css';

const MineSweeper = () => {
  const [board, setBoard] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [flagMode, setFlagMode] = useState(false);
  const [mineCount, setMineCount] = useState(10);
  const [flagCount, setFlagCount] = useState(0);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [gameStarted, setGameStarted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const BOARD_SIZE = 9;
  const TOTAL_MINES = 10;

  // Initialize board
  const initializeBoard = () => {
    // Create empty board
    let newBoard = Array(BOARD_SIZE).fill().map(() =>
      Array(BOARD_SIZE).fill().map(() => ({
        isMine: false,
        isRevealed: false,
        isFlagged: false,
        neighborMines: 0
      }))
    );

    // Place mines randomly
    let minesPlaced = 0;
    while (minesPlaced < TOTAL_MINES) {
      const x = Math.floor(Math.random() * BOARD_SIZE);
      const y = Math.floor(Math.random() * BOARD_SIZE);
      if (!newBoard[x][y].isMine) {
        newBoard[x][y].isMine = true;
        minesPlaced++;
      }
    }

    // Calculate neighbor mines
    for (let i = 0; i < BOARD_SIZE; i++) {
      for (let j = 0; j < BOARD_SIZE; j++) {
        if (!newBoard[i][j].isMine) {
          let count = 0;
          // Check all 8 neighbors
          for (let di = -1; di <= 1; di++) {
            for (let dj = -1; dj <= 1; dj++) {
              if (i + di >= 0 && i + di < BOARD_SIZE && 
                  j + dj >= 0 && j + dj < BOARD_SIZE) {
                if (newBoard[i + di][j + dj].isMine) count++;
              }
            }
          }
          newBoard[i][j].neighborMines = count;
        }
      }
    }

    return newBoard;
  };

  // Start new game
  const startNewGame = () => {
    setBoard(initializeBoard());
    setGameOver(false);
    setGameWon(false);
    setFlagMode(false);
    setFlagCount(0);
    setTimeElapsed(0);
    setGameStarted(false);
    setShowPopup(false);
  };

  // Initialize game
  useEffect(() => {
    startNewGame();
  }, []);

  // Timer
  useEffect(() => {
    let timer;
    if (gameStarted && !gameOver && !gameWon) {
      timer = setInterval(() => {
        setTimeElapsed(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStarted, gameOver, gameWon]);

  // Reveal cell and its neighbors if empty
  const revealCell = (x, y) => {
    if (!gameStarted) setGameStarted(true);
    
    const newBoard = [...board];
    if (newBoard[x][y].isRevealed || newBoard[x][y].isFlagged) return;

    newBoard[x][y].isRevealed = true;

    if (newBoard[x][y].isMine) {
      setGameOver(true);
      // Reveal all mines
      newBoard.forEach(row => row.forEach(cell => {
        if (cell.isMine) cell.isRevealed = true;
      }));
      setShowPopup(true); // Show popup on mine click
    } else if (newBoard[x][y].neighborMines === 0) {
      // Reveal neighbors for empty cells
      for (let i = -1; i <= 1; i++) {
        for (let j = -1; j <= 1; j++) {
          if (x + i >= 0 && x + i < BOARD_SIZE && 
              y + j >= 0 && y + j < BOARD_SIZE) {
            if (!newBoard[x + i][y + j].isRevealed) {
              revealCell(x + i, y + j);
            }
          }
        }
      }
    }

    setBoard(newBoard);
    checkWinCondition(newBoard);
  };

  // Toggle flag on cell
  const toggleFlag = (x, y) => {
    if (!gameStarted) setGameStarted(true);
    
    const newBoard = [...board];
    if (!newBoard[x][y].isRevealed) {
      if (newBoard[x][y].isFlagged) {
        newBoard[x][y].isFlagged = false;
        setFlagCount(prev => prev - 1);
      } else if (flagCount < TOTAL_MINES) {
        newBoard[x][y].isFlagged = true;
        setFlagCount(prev => prev + 1);
      }
      setBoard(newBoard);
      checkWinCondition(newBoard);
    }
  };

  // Check if game is won
  const checkWinCondition = (currentBoard) => {
    const won = currentBoard.every(row =>
      row.every(cell =>
        (cell.isMine && cell.isFlagged) ||
        (!cell.isMine && cell.isRevealed)
      )
    );
    if (won) setGameWon(true);
  };

  // Handle cell click
  const handleCellClick = (x, y) => {
    if (gameOver || gameWon) return;
    if (flagMode) {
      toggleFlag(x, y);
    } else {
      revealCell(x, y);
    }
  };

  // Get cell content
  const getCellContent = (cell) => {
    if (cell.isFlagged) return '🚩';
    if (!cell.isRevealed) return '';
    if (cell.isMine) return '💣';
    return cell.neighborMines || '';
  };

  // Add popup close handler
  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="ms-container">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} alt="Exit" className="exit-png" />
      </button>
      <div className="ms-header">
        <div className="ms-display ms-display--mines">
          {String(TOTAL_MINES - flagCount).padStart(3, '0')}
        </div>
        <button className="ms-reset" onClick={startNewGame}>
          {gameOver ? '×' : gameWon ? '♥' : '•'}
        </button>
        <div className="ms-display ms-display--timer">
          {String(timeElapsed).padStart(3, '0')}
        </div>
      </div>

      <div className="ms-board">
        {board.map((row, i) => (
          <div key={i} className="ms-row">
            {row.map((cell, j) => (
              <div
                key={`${i}-${j}`}
                className={`ms-cell 
                  ${cell.isRevealed ? 'ms-cell--revealed' : ''} 
                  ${cell.isFlagged ? 'ms-cell--flagged' : ''} 
                  ${cell.isRevealed && cell.neighborMines ? `ms-cell--n${cell.neighborMines}` : ''}`}
                onClick={() => handleCellClick(i, j)}
                onContextMenu={(e) => {
                  e.preventDefault();
                  toggleFlag(i, j);
                }}
              >
                {getCellContent(cell)}
              </div>
            ))}
          </div>
        ))}
      </div>

      <button 
        className={`ms-flag-toggle ${flagMode ? 'ms-flag-toggle--active' : ''}`}
        onClick={() => setFlagMode(!flagMode)}
      >
        Flag Mode: {flagMode ? 'ON' : 'OFF'}
      </button>

      {showPopup && (gameOver || gameWon) && (
        <div className="ms-overlay">
          <div className="ms-popup">
            <h2 className="ms-popup__title">
              {gameOver ? 'Game Over!' : 'Congratulations!'}
            </h2>
            <p className="ms-popup__text">
              {gameOver ? 'You hit a mine!' : 'You won the game!'}
            </p>
            <p className="ms-popup__time">
              Time: {timeElapsed} seconds
            </p>
            <div className="ms-popup__buttons">
              <button className="ms-popup__button" onClick={startNewGame}>
                {gameOver ? 'New Game' : 'Play Again'}
              </button>
              <button className="ms-popup__button" onClick={closePopup}>
                View Board
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MineSweeper;
