// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
  padding: 0;
  background-color: transparent;
  box-sizing: border-box;
}
.navbar .rectangle-bar {
  width: 102%;
  height: 80px;
  margin: 0 auto;
  padding: 0;
  background-size: cover;
  background-position: center;
  margin-left: 8px;
  z-index: 1;
}

.icons-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-47%, -50%);
  width: 102%;
  max-width: 650px;
  z-index: 2; 
}
.icon {
  width: 90px;
  height: 90px;
  z-index: 4;
}
.icon-container.active {
  background-color: rgb(80, 13, 166);
  border-radius: 10px;
  width: 65px; 
  height: 65px; 
}
.highlight-bar {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-48%, -50%);
  width: 60px; 
  height: 55px; 
  z-index: 3;
}

@media (max-width: 768px) {
  .icon {
      width: 40px;
      height: 40px;
  }
}

@media (max-width: 480px) {
  .icon {
      width: 70px;
      height: 70px;
  }

}`, "",{"version":3,"sources":["webpack://./src/Navbar.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,6BAA6B;EAC7B,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,UAAU;EACV,sBAAsB;EACtB,2BAA2B;EAC3B,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,gBAAgB;EAChB,UAAU;AACZ;AACA;EACE,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;AACA;EACE,kCAAkC;EAClC,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE;MACI,WAAW;MACX,YAAY;EAChB;AACF;;AAEA;EACE;MACI,WAAW;MACX,YAAY;EAChB;;AAEF","sourcesContent":[".navbar {\r\n  padding: 0;\r\n  background-color: transparent;\r\n  box-sizing: border-box;\r\n}\r\n.navbar .rectangle-bar {\r\n  width: 102%;\r\n  height: 80px;\r\n  margin: 0 auto;\r\n  padding: 0;\r\n  background-size: cover;\r\n  background-position: center;\r\n  margin-left: 8px;\r\n  z-index: 1;\r\n}\r\n\r\n.icons-container {\r\n  position: absolute;\r\n  top: 50%;\r\n  left: 50%;\r\n  transform: translate(-47%, -50%);\r\n  width: 102%;\r\n  max-width: 650px;\r\n  z-index: 2; \r\n}\r\n.icon {\r\n  width: 90px;\r\n  height: 90px;\r\n  z-index: 4;\r\n}\r\n.icon-container.active {\r\n  background-color: rgb(80, 13, 166);\r\n  border-radius: 10px;\r\n  width: 65px; \r\n  height: 65px; \r\n}\r\n.highlight-bar {\r\n  position: absolute;\r\n  top: 45%;\r\n  left: 50%;\r\n  transform: translate(-48%, -50%);\r\n  width: 60px; \r\n  height: 55px; \r\n  z-index: 3;\r\n}\r\n\r\n@media (max-width: 768px) {\r\n  .icon {\r\n      width: 40px;\r\n      height: 40px;\r\n  }\r\n}\r\n\r\n@media (max-width: 480px) {\r\n  .icon {\r\n      width: 70px;\r\n      height: 70px;\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
