import React, { useEffect } from 'react';

function GameOver({ score, onRestart, postscore }) {
  useEffect(() => {
    // Call postscore when the game is over
    postscore(); // Post the score to the server
  }, [score, postscore]);

  return (
    <div className="game-over">
      <h1>Game Over!</h1>
      <p>Final Score: {score}</p>
      <button onClick={onRestart}>Play Again</button>
    </div>
  );
}

export default GameOver;
