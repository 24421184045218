import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Exit from './assets/Exit.png'
import './WordScramble.css';

const WordScramble = () => {
  const [currentWord, setCurrentWord] = useState('');
  const [scrambledWord, setScrambledWord] = useState('');
  const [userInput, setUserInput] = useState('');
  const [score, setScore] = useState(0);
  const [timeLeft, setTimeLeft] = useState(30);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [level, setLevel] = useState(1);
  const [streak, setStreak] = useState(0);
  const inputRef = useRef(null);
  const [usedWords, setUsedWords] = useState(new Set());
  const [gameCompleted, setGameCompleted] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Expanded word lists for 10 levels
  const wordLists = {
    1: [
      { word: 'CAT', hint: 'A furry pet' },
      { word: 'DOG', hint: 'Man\'s best friend' },
      { word: 'HAT', hint: 'Head covering' },
      { word: 'SUN', hint: 'Shines in the sky' },
      { word: 'BAT', hint: 'Flying mammal' }
    ],
    2: [
      { word: 'BIRD', hint: 'Flying animal' },
      { word: 'FISH', hint: 'Lives in water' },
      { word: 'CAKE', hint: 'Sweet dessert' },
      { word: 'BOOK', hint: 'You read this' },
      { word: 'TREE', hint: 'Grows in forests' }
    ],
    3: [
      { word: 'HOUSE', hint: 'Place to live' },
      { word: 'PHONE', hint: 'Communication device' },
      { word: 'CHAIR', hint: 'Sit on it' },
      { word: 'SMILE', hint: 'Happy expression' },
      { word: 'BEACH', hint: 'Sandy shore' }
    ],
    4: [
      { word: 'PENCIL', hint: 'Writing tool' },
      { word: 'GARDEN', hint: 'Plants grow here' },
      { word: 'WINDOW', hint: 'Look through it' },
      { word: 'BRIDGE', hint: 'Crosses water' },
      { word: 'COFFEE', hint: 'Morning drink' }
    ],
    5: [
      { word: 'RAINBOW', hint: 'Colorful arc in sky' },
      { word: 'PICTURE', hint: 'Visual image' },
      { word: 'KITCHEN', hint: 'Cooking room' },
      { word: 'BICYCLE', hint: 'Two-wheeler' },
      { word: 'DOLPHIN', hint: 'Smart sea mammal' }
    ],
    6: [
      { word: 'COMPUTER', hint: 'Electronic device' },
      { word: 'ELEPHANT', hint: 'Large gray animal' },
      { word: 'BIRTHDAY', hint: 'Annual celebration' },
      { word: 'MOUNTAIN', hint: 'Very high landform' },
      { word: 'UMBRELLA', hint: 'Rain protection' }
    ],
    7: [
      { word: 'DINOSAUR', hint: 'Extinct creature' },
      { word: 'KEYBOARD', hint: 'Typing device' },
      { word: 'HOSPITAL', hint: 'Medical facility' },
      { word: 'BUTTERFLY', hint: 'Colorful insect' },
      { word: 'TREASURE', hint: 'Valuable items' }
    ],
    8: [
      { word: 'CHOCOLATE', hint: 'Sweet brown treat' },
      { word: 'ASTRONAUT', hint: 'Space explorer' },
      { word: 'PINEAPPLE', hint: 'Tropical fruit' },
      { word: 'SNOWFLAKE', hint: 'Winter crystal' },
      { word: 'ORCHESTRA', hint: 'Music group' }
    ],
    9: [
      { word: 'TELEPHONE', hint: 'Communication device' },
      { word: 'CROCODILE', hint: 'Reptile predator' },
      { word: 'SUBMARINE', hint: 'Underwater vessel' },
      { word: 'PAINTBRUSH', hint: 'Art tool' },
      { word: 'SUNFLOWER', hint: 'Yellow bloom' }
    ],
    10: [
      { word: 'SPACECRAFT', hint: 'Space vehicle' },
      { word: 'BASKETBALL', hint: 'Sport with hoops' },
      { word: 'LIGHTHOUSE', hint: 'Coastal beacon' },
      { word: 'WATERMELON', hint: 'Large sweet fruit' },
      { word: 'DRAGONFLY', hint: 'Flying insect' }
    ]
  };

  const scrambleWord = (word) => {
    const shuffled = word.split('')
      .sort(() => Math.random() - 0.5)
      .join('');
    return shuffled === word ? scrambleWord(word) : shuffled;
  };

  const getNewWord = () => {
    const currentList = wordLists[level];
    const availableWords = currentList.filter(word => !usedWords.has(word.word));

    if (availableWords.length === 0) {
      if (level === 10) {
        setGameCompleted(true);
        setIsPlaying(false);
        return;
      } else {
        setLevel(prev => Math.min(prev + 1, 10));
        setUsedWords(new Set());
        return getNewWord();
      }
    }

    const randomWord = availableWords[Math.floor(Math.random() * availableWords.length)];
    setCurrentWord(randomWord.word);
    setScrambledWord(scrambleWord(randomWord.word));
    setUsedWords(prev => new Set([...prev, randomWord.word]));
    setUserInput('');
    setShowHint(false);
    setTimeLeft(Math.max(45 - (level * 3), 20)); // Decreasing time per level
    inputRef.current?.focus();
  };

  const startGame = () => {
    setIsPlaying(true);
    setScore(0);
    setStreak(0);
    setLevel(1);
    setUsedWords(new Set());
    setGameCompleted(false);
    getNewWord();
  };

  const checkAnswer = () => {
    if (userInput.toUpperCase() === currentWord) {
      const basePoints = 100;
      const timeBonus = timeLeft * 2;
      const streakBonus = streak * 10;
      const levelBonus = level * 50;
      
      setScore(prev => prev + basePoints + timeBonus + streakBonus + levelBonus);
      setStreak(prev => prev + 1);
      
      if (streak + 1 >= 2) { // Reduced streak requirement for level advancement
        if (level < 10) {
          setLevel(prev => prev + 1);
          setStreak(0);
          setUsedWords(new Set());
        }
      }
      
      getNewWord();
    }
  };

  useEffect(() => {
    let timer;
    if (isPlaying && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(prev => prev - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      setIsPlaying(false);
    }
    return () => clearInterval(timer);
  }, [isPlaying, timeLeft]);

  const handleInputChange = (e) => {
    setUserInput(e.target.value.toUpperCase());
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      checkAnswer();
    }
  };

  // Add level progress indicator
  const renderLevelProgress = () => {
    return (
      <div className="level-progress">
        <div className="level-bar">
          {Array.from({ length: 10 }, (_, i) => (
            <div 
              key={i} 
              className={`level-segment ${i + 1 <= level ? 'active' : ''}`}
            >
              {i + 1}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="word-scramble">
      {!isPlaying ? (
        <div className="start-screen">
          <h1>Word Scramble</h1>
          <p>Unscramble the words before time runs out!</p>
          <button onClick={startGame}>Start Game</button>
          {score > 0 && (
            <div className="final-score">
              <h2>{gameCompleted ? 'Congratulations!' : 'Game Over!'}</h2>
              <p>Final Score: {score}</p>
              <p>Level Reached: {level}</p>
              {gameCompleted && (
                <p className="completion-message">
                  You've completed all words in all levels!
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="game-container">  
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png'/>
      </button>
          <div className="game-header">
            {renderLevelProgress()}
            <div className="stats">
              <div>Score: {score}</div>
              <div>Level: {level}/10</div>
              <div>Time: {timeLeft}s</div>
              <div>Streak: {streak}/2</div>
            </div>
          </div>

          <div className="game-content">
            <div className="scrambled-word">{scrambledWord}</div>
            
            <div className="input-container">
              <input
                ref={inputRef}
                type="text"
                value={userInput}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                placeholder="Type your answer"
                maxLength={currentWord.length}
                autoFocus
              />
            </div>

            <div className="controls">
              <button onClick={checkAnswer}>Submit</button>
              <button 
                onClick={() => setShowHint(true)}
                className="hint-button"
                disabled={showHint}
              >
                Show Hint
              </button>
            </div>

            {showHint && (
              <div className="hint">
                Hint: {wordLists[level].find(w => w.word === currentWord)?.hint}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default WordScramble;
