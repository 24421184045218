import React, { useState, useEffect, useCallback } from 'react';
import Exit from './assets/Exit.png';
import { useNavigate } from 'react-router-dom';
import './Game2048.css';

const Game2048 = () => {
  const [board, setBoard] = useState([]);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [won, setWon] = useState(false);
  const [activeCell, setActiveCell] = useState({ x: -1, y: -1 });
  const navigate = useNavigate();

  const initBoard = () => {
    let newBoard = Array(4).fill().map(() => Array(4).fill(0));
    addNewTile(addNewTile(newBoard));
    return newBoard;
  };

  useEffect(() => {
    setBoard(initBoard());
  }, []);

  const addNewTile = (currentBoard) => {
    let available = [];
    currentBoard.forEach((row, i) => {
      row.forEach((cell, j) => {
        if (cell === 0) {
          available.push({ x: i, y: j });
        }
      });
    });

    if (available.length > 0) {
      const randomCell = available[Math.floor(Math.random() * available.length)];
      const newValue = Math.random() < 0.9 ? 2 : 4;
      currentBoard[randomCell.x][randomCell.y] = newValue;
    }
    return currentBoard;
  };

  const handleCellClick = (x, y) => {
    setActiveCell({ x, y });
  };

  const moveTiles = useCallback(
    (direction) => {
      if (gameOver || won) return;
      const { x, y } = activeCell;
      if (x === -1 || y === -1) return; // No active tile selected

      let newBoard = JSON.parse(JSON.stringify(board));
      let mergeOccurred = false;
      let newScore = score;

      const getNextPos = (x, y, direction) => {
        if (direction === 'left') return { x, y: y - 1 };
        if (direction === 'right') return { x, y: y + 1 };
        if (direction === 'up') return { x: x - 1, y };
        if (direction === 'down') return { x: x + 1, y };
        return { x, y };
      };

      const { x: nx, y: ny } = getNextPos(x, y, direction);

      if (
        nx >= 0 &&
        ny >= 0 &&
        nx < 4 &&
        ny < 4 &&
        newBoard[nx][ny] === 0
      ) {
        newBoard[nx][ny] = newBoard[x][y];
        newBoard[x][y] = 0;
        mergeOccurred = true;
      } else if (
        nx >= 0 &&
        ny >= 0 &&
        nx < 4 &&
        ny < 4 &&
        newBoard[nx][ny] === newBoard[x][y]
      ) {
        newBoard[nx][ny] *= 2;
        newScore += newBoard[nx][ny];
        newBoard[x][y] = 0;
        mergeOccurred = true;
      }

      if (mergeOccurred) {
        addNewTile(newBoard);
      }

      setBoard(newBoard);
      setScore(newScore);
      setActiveCell({ x: -1, y: -1 }); // Clear active cell
    },
    [activeCell, board, score, gameOver, won]
  );

  useEffect(() => {
    const handleKeyPress = (e) => {
      e.preventDefault();
      if (activeCell.x === -1 || activeCell.y === -1) return;

      switch (e.key) {
        case 'ArrowLeft':
          moveTiles('left');
          break;
        case 'ArrowRight':
          moveTiles('right');
          break;
        case 'ArrowUp':
          moveTiles('up');
          break;
        case 'ArrowDown':
          moveTiles('down');
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [moveTiles, activeCell]);

  const resetGame = () => {
    setBoard(initBoard());
    setScore(0);
    setGameOver(false);
    setWon(false);
    setActiveCell({ x: -1, y: -1 });
  };

  return (
    <div className="game-2048">
       <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} alt="Exit" className="exit-png" />
      </button>
      <div className="header">
        <h1>2048</h1>
        <div className="score-containers">
          <div className="score-labels">Score:</div>
          <div className="score">{score}</div>
        </div>
      </div>

      <div className="game-boarding">
        {board.map((row, i) => (
          <div key={i} className="row">
            {row.map((cell, j) => (
              <div
                key={`${i}-${j}`}
                className={`cell value-${cell} ${activeCell.x === i && activeCell.y === j ? 'active-cell' : ''}`}
                onClick={() => handleCellClick(i, j)}
              >
                {cell !== 0 && cell}
              </div>
            ))}
          </div>
        ))}
      </div>

      {(gameOver || won) && (
        <div className="game-overlay">
          <div className="game-message">
            <h2>{won ? 'You Won!' : 'Game Over!'}</h2>
            <p>Score: {score}</p>
            <button onClick={resetGame}>Play Again</button>
          </div>
        </div>
      )}

      <button className="reset-button" onClick={resetGame}>New Game</button>
    </div>
  );
};

export default Game2048;
