// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Home.css */
.home-container {
  background-color: #242037;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .quiz-text {
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 20px;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .user-icon-container {
    position: absolute;
    left: 20px;
    top: 20px;
    display: flex;
    align-items: center;
  }
  
  .user-icon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .username {
    color: white;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .coins-bar-container {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
  }
  
  .coins-bar {
    width: 100px;
    height: 35px;
    margin-right: 10px;
    position: relative;
  }
  
  .coins {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    font-family: Arial, Helvetica, sans-serif;
    transform: translate(-50%, -50%);
  }`, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,yBAAyB;IACvB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,yCAAyC;EAC3C;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,yCAAyC;EAC3C;;EAEA;IACE,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,yCAAyC;IACzC,gCAAgC;EAClC","sourcesContent":["/* Home.css */\r\n.home-container {\r\n  background-color: #242037;\r\n    height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n  }\r\n  \r\n  .quiz-text {\r\n    font-size: 48px;\r\n    font-weight: bold;\r\n    margin-bottom: 20px;\r\n    color: white;\r\n    font-family: Arial, Helvetica, sans-serif;\r\n  }\r\n  \r\n  .user-icon-container {\r\n    position: absolute;\r\n    left: 20px;\r\n    top: 20px;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .user-icon {\r\n    width: 40px;\r\n    height: 40px;\r\n    margin-right: 10px;\r\n  }\r\n  \r\n  .username {\r\n    color: white;\r\n    font-size: 18px;\r\n    font-family: Arial, Helvetica, sans-serif;\r\n  }\r\n  \r\n  .coins-bar-container {\r\n    position: absolute;\r\n    top: 20px;\r\n    right: 20px;\r\n    display: flex;\r\n    align-items: center;\r\n  }\r\n  \r\n  .coins-bar {\r\n    width: 100px;\r\n    height: 35px;\r\n    margin-right: 10px;\r\n    position: relative;\r\n  }\r\n  \r\n  .coins {\r\n    color: white;\r\n    font-size: 18px;\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    font-family: Arial, Helvetica, sans-serif;\r\n    transform: translate(-50%, -50%);\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
