import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Exit from './assets/Exit.png'
import './NumberGuessing.css'; // Optional CSS for styling

const NumberGuessingGame = () => {
  // State variables
  const [computerNumber, setComputerNumber] = useState(() => generateRandomNumber());
  const [guess, setGuess] = useState('');
  const [message, setMessage] = useState('');
  const [attempts, setAttempts] = useState(0);
  const navigate = useNavigate();

  // Generate a random number between 1 and 100
  function generateRandomNumber() {
    return Math.floor(Math.random() * 100) + 1;
  }

  // Handle user input change
  const handleInputChange = (event) => {
    setGuess(event.target.value);
  };

  // Handle guess submission
  const handleGuessSubmit = (event) => {
    event.preventDefault();
    const guessedNumber = parseInt(guess, 10);

    if (isNaN(guessedNumber)) {
      setMessage('Please enter a valid number!');
      return;
    }

    setAttempts((prevAttempts) => prevAttempts + 1);

    if (guessedNumber === computerNumber) {
      setMessage(`Congratulations! You've guessed it in ${attempts + 1} attempts.`);
    } else if (guessedNumber > computerNumber) {
      setMessage('Lower! Try again.');
    } else {
      setMessage('Higher! Try again.');
    }
  };

  // Reset the game
  const resetGame = () => {
    setComputerNumber(generateRandomNumber());
    setGuess('');
    setMessage('');
    setAttempts(0);
  };


  return (
    <div className="number-guessing-container">
       <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png'/>
      </button>
      <h1>Number Guessing Game</h1>
      <p>Guess a number between 1 and 100:</p>
      <form onSubmit={handleGuessSubmit}>
        <input
          type="number"
          value={guess}
          onChange={handleInputChange}
          placeholder="Enter your guess"
        />
        <button type="submit">Guess</button>
      </form>

      {message && <p className="message">{message}</p>}

      <p>Attempts: {attempts}</p>

      <button onClick={resetGame} className="reset-button">
        Restart Game
      </button>
    </div>
  );
};

export default NumberGuessingGame;


