import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import './Dailyrewards.css';
import Dailyicon from './assets/DailyRewardsIcon.png';
import Coin from './assets/Coin.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from './Navbar';

const DailyRewardsPage = () => {
    const navigate = useNavigate();
    const [zoomedDay, setZoomedDay] = useState(null);
    const [username, setUsername] = useState('');
    const [completedDays, setCompletedDays] = useState([]); // Track completed days
    const [nextAvailableDay, setNextAvailableDay] = useState(null);
   const supabase = createClient(
       'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
       'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
     );

    // Fetch username and daily reward status from Supabase
    useEffect(() => {
        const storedUsername = localStorage.getItem('username');
        if (storedUsername) {
            setUsername(storedUsername);
            fetchCompletedDays(storedUsername); // Fetch completed rewards on load
        } else {
            console.warn('Username not found in localStorage');
        }
    }, []);

    // Fetch completed rewards from the database
    const fetchCompletedDays = async (username) => {
        try {
            const { data, error } = await supabase
                .from('Dailyrewards')
                .select('*') // Fetch all fields
                .eq('username', username)
                .single();

            if (error) {
                console.error('Error fetching daily rewards:', error);
                return;
            }

            // Find completed days
            const completed = Object.keys(data).filter(day => data[day] === 'Completed');
            setCompletedDays(completed); // Update state with completed days

            const nextDay = getNextAvailableDay(data);
            setNextAvailableDay(nextDay);

        } catch (error) {
            console.error('Error during fetching completed days:', error);
        }
    };

    const handleExitClick = () => {
        navigate('/'); // Navigate to the homepage
    };

    const handleDayClick = async (day) => {

        if (day !== nextAvailableDay) {
            console.warn(`Day ${day} is not available to claim.`);
            return;
        }

        setZoomedDay(day);
    
        // Check if the user has already claimed the reward for the day
        const isRewardClaimed = await checkIfRewardClaimed(day);
        if (isRewardClaimed) {
            console.log(`Reward for Day ${day} has already been claimed.`);
            return; // Do not proceed if the reward is already claimed
        }
    
        // Proceed with posting coins only after confirming that the reward is not yet claimed
        const coinsToAdd = day * 50; // Coin calculation
        await postRewards(day); // Mark the reward as completed
        await postCoins(coinsToAdd); // Add the coins
    };
    
    // Function to check if the reward for a given day is completed
    const checkIfRewardClaimed = async (day) => {
        if (!username) {
            console.error('Username not set. Cannot check reward status.');
            return false;
        }
    
        const dayField = `Day${day}`;
        try {
            const { data: userData, error: fetchError } = await supabase
                .from('Dailyrewards')
                .select(dayField)
                .eq('username', username)
                .single();
    
            if (fetchError || !userData) {
                console.error('Error fetching user data:', fetchError);
                return false;
            }
    
            return userData[dayField] === 'Completed'; // Return true if the reward is already completed
        } catch (error) {
            console.error('Error during reward check:', error);
            return false;
        }
    };

    const getNextAvailableDay = (data) => {
        const lastClaimedDate = data?.lastClaimedDate || null;
    
        if (lastClaimedDate) {
            const now = new Date();
            const lastClaimTime = new Date(lastClaimedDate);
            const timeDifferenceInMs = now - lastClaimTime;
            const hoursPassed = timeDifferenceInMs / (1000 * 60 * 60); // Convert ms to hours
    
            // If 24 hours haven't passed, return null (no next day is available)
            if (hoursPassed < 24) {
                return null;
            }
        }
    
        // Find the next incomplete day
        for (let i = 1; i <= 7; i++) {
            const dayField = `Day${i}`;
            if (!data[dayField] || data[dayField] !== 'Completed') {
                return i; // Return the next incomplete day
            }
        }
    
        return null; // All days are completed
    };
    
    
    
    // Function to post coins to Supabase
    const postCoins = async (coinsToAdd) => {
        if (!username) {
            console.error('Username not set. Cannot post coins.');
            return;
        }
    
        try {
            const { data: userData, error: fetchError } = await supabase
                .from('usersdata')
                .select('coins')
                .eq('username', username)
                .single();
    
            if (fetchError || !userData) {
                console.error('Error fetching user data:', fetchError);
                return;
            }
    
            const updatedCoins = userData.coins + coinsToAdd;
    
            const { error: postError } = await supabase
                .from('usersdata')
                .update({ coins: updatedCoins })
                .eq('username', username);
    
            if (postError) {
                console.error('Error posting coins:', postError);
            } else {
                console.log(`Coins updated successfully for ${username}: +${coinsToAdd} coins`);
            }
        } catch (error) {
            console.error('Error during coin posting:', error);
        }
    };
    const postRewards = async (day) => {
        if (!username) {
            console.error('Username not set. Cannot post rewards.');
            return;
        }
    
        const dayField = `Day${day}`;
        const now = new Date().toISOString(); // Current timestamp


        try {
            // Fetch the user data for Dailyrewards
            const { data: userData, error: fetchError } = await supabase
                .from('Dailyrewards')
                .select('*') // Fetch all fields to check if DayX exists
                .eq('username', username)
                .single();
    
            if (fetchError || !userData) {
                console.error('Error fetching user data:', fetchError);
                return;
            }
    
            // Check if the specific dayField exists in the user data (not just 'Completed')
            if (!userData.hasOwnProperty(dayField)) {
                console.log(`Field ${dayField} does not exist. Skipping reward posting.`);
                return; // Skip if the specific day field doesn't exist
            }
    
            // Check if the reward for this day is already claimed
            if (userData[dayField] === 'Completed') {
                console.log(`Reward for ${dayField} already claimed.`);
                return; // Avoid re-processing if already completed
            }
    
            // If the field exists and reward not completed, update as "Completed"
            const { error: postError } = await supabase
                .from('Dailyrewards')
                .update({ [dayField]: 'Completed',
                    lastClaimedDate: now
                 })
                .eq('username', username);
    
            if (postError) {
                console.error('Error updating daily reward:', postError);
            } else {
                console.log(`${dayField} marked as "Completed" for ${username}`);
                fetchCompletedDays(username);
            }
    
        } catch (error) {
            console.error('Error during reward posting:', error);
        }
    };
 
    return (
        <div className='dailyrewardspaging'>
            <div className="rewards-heading">
                <h1 className='Daily-text'><b>Daily <br/> Rewards</b></h1>
                <img src={Dailyicon} alt="Daily Rewards" className='DailyRewards-img'/>
            </div>

            <div className="container">
                <div className="row justify-content-center w-100 rewards-contents">
                    {[...Array(7)].map((_, index) => {
                        const day = index + 1;
                        const amount = day * 50;
                        const isCompleted = completedDays.includes(`Day${day}`);
                        const isAvailable = day === nextAvailableDay;
                        return (
                            <div
                                className={`col-3 dr-bg rewards-bars mx-1 
                                    ${isCompleted ? 'completed' : ''} 
                                    ${isAvailable ? 'available' : 'disabled'}`}
                                key={day}
                                onClick={() => handleDayClick(day)}
                                style={{ cursor: isAvailable ? 'pointer' : 'not-allowed' }}
                            >
                                <div className={`day-content w-100 ${zoomedDay === day ? 'zoomed' : ''}`}>
                                    <span className='card-header'>Day {day}</span>
                                    <img src={Coin} alt="Coin" className='coin-img'/>
                                    <span className='amount'>{amount}</span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <Navbar activeLink='/dailyrewards' />
        </div>
    );
};

export default DailyRewardsPage;