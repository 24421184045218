import React, { useState, useEffect } from 'react';
import Exit from './assets/Exit.png';
import { useNavigate } from 'react-router-dom';
import './TapNumbers.css';
import { createClient } from '@supabase/supabase-js';

// Supabase Client Initialization
const supabase = createClient(
  'https://supabasekong-fg8gw04sg00cg0g8ocwkok0s.anil.one',
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJzdXBhYmFzZSIsImlhdCI6MTczMTU2MDk0MCwiZXhwIjo0ODg3MjM0NTQwLCJyb2xlIjoiYW5vbiJ9.ZDadCTZz-iLDV63NJ4ftVfcavwrCYJFs4YSG5NO6eiE'
);

function TapNumbers() {
  const [username, setUsername] = useState('');
  const [numbers, setNumbers] = useState([]);
  const [currentNumber, setCurrentNumber] = useState(null);
  const [timer, setTimer] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const usernameParam = urlParams.get('username');
    if (usernameParam) {
      setUsername(usernameParam);
      localStorage.setItem('username', usernameParam);
    } else {
      const storedUsername = localStorage.getItem('username');
      if (storedUsername) {
        setUsername(storedUsername);
      }
    }
  }, []);

  const generateGame = () => {
    const availableNumbers = Array.from({ length: 30 }, (_, i) => i + 1);
    const selectedNumbers = [];
    for (let i = 0; i < 12; i++) {
      const randomIndex = Math.floor(Math.random() * availableNumbers.length);
      selectedNumbers.push(availableNumbers.splice(randomIndex, 1)[0]);
    }
    const shuffledNumbers = selectedNumbers.sort(() => Math.random() - 0.5);

    const gameNumbers = shuffledNumbers.map(num => ({
      value: num,
      type: 'number',
      status: ''
    }));

    const distractors = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      .split('')
      .sort(() => Math.random() - 0.5)
      .slice(0, 4)
      .map(letter => ({
        value: letter,
        type: 'distractor'
      }));

    const allItems = [...gameNumbers, ...distractors].sort(
      () => Math.random() - 0.5
    );

    setNumbers(allItems);
    setCurrentNumber(shuffledNumbers[Math.floor(Math.random() * shuffledNumbers.length)]);
    setTimer(0);
    setGameActive(true);
    setGameOver(false);
    setGameWon(false);
  };

  const handleItemClick = (item) => {
    if (!gameActive || item.type === 'distractor') return;

    if (item.value === currentNumber) {
      const updatedNumbers = numbers.map(num =>
        num === item ? { ...num, status: 'correct' } : num
      );
      setNumbers(updatedNumbers);

      const remainingNumbers = updatedNumbers.filter(
        n => n.type === 'number' && n.status !== 'correct'
      );

      if (remainingNumbers.length > 0) {
        setCurrentNumber(
          remainingNumbers[Math.floor(Math.random() * remainingNumbers.length)].value
        );
      } else {
        setGameActive(false);
        setGameWon(true);
      }
    } else {
      setGameActive(false);
      setGameOver(true);
    }
  };

  useEffect(() => {
    let interval;
    if (gameActive) {
      interval = setInterval(() => {
        setTimer(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [gameActive]);

  const postTime = async () => {
    try {
      const { data, error: fetchError } = await supabase
        .from('usersdata')
        .select('tapnumbers')
        .eq('username', username)
        .single();

      if (fetchError || !data) return console.error('Fetch Error:', fetchError?.message);

      const existingTime = data.tapnumbers || Number.MAX_SAFE_INTEGER;

      if (timer < existingTime) {
        const { error: updateError } = await supabase
          .from('usersdata')
          .update({ tapnumbers: timer })
          .eq('username', username);

        if (updateError) console.error('Update Error:', updateError.message);
      }
    } catch (error) {
      console.error('Error posting time:', error);
    }
  };

  useEffect(() => {
    if (!gameActive && gameWon && timer > 0 && username) {
      postTime();
    }
  }, [gameWon, gameActive]);

  return (
    <div className="game-container">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} alt="Exit" className="exit-png" />
      </button>
      <div className="game-header">
        <div className="timer-container">
          <div className="timer">Time: {timer} seconds</div>
          {gameActive && (
            <div className="current-number">
              Find number: <span>{currentNumber}</span>
            </div>
          )}
        </div>
      </div>
      <div className="number-grid">
        {numbers.map((item, index) => (
          <div
            key={index}
            className={`item ${item.type} ${item.status || ''}`}
            onClick={() => handleItemClick(item)}
          >
            {item.value}
          </div>
        ))}
      </div>
      {gameWon && (
        <div className="game-won-popup">
          <div className="popup-content">
            <h2>Congratulations!</h2>
            <p className='time-tap'>Your Time: {timer} seconds</p>
            <button className="start-button" onClick={generateGame}>
              Restart Game
            </button>
          </div>
        </div>
      )}
      {gameOver && (
        <div className="game-over-popup">
          <div className="popup-content">
            <h2>Game Over</h2>
            <p>You clicked the wrong number!</p>
            <button className="start-button" onClick={generateGame}>
              Restart Game
            </button>
          </div>
        </div>
      )}
      <div className="game-footer">
        <button className="start-button" onClick={generateGame}>
          {gameActive ? 'Restart Game' : 'Start Game'}
        </button>
      </div>
    </div>
  );
}

export default TapNumbers;
