import React, { useState } from 'react';
import Exit from './assets/Exit.png';
import { useNavigate } from 'react-router-dom';
import './Rock.css';

const Rock = () => {
  const [userChoice, setUserChoice] = useState(null);
  const [computerChoice, setComputerChoice] = useState(null);
  const [result, setResult] = useState('');
  const [score, setScore] = useState({ user: 0, computer: 0 });
  const [countdown, setCountdown] = useState(null);
  const [showChoices, setShowChoices] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  const choices = ['rock', 'paper', 'scissors'];

  const handleClick = (choice) => {
    setUserChoice(choice);
    setShowChoices(false);
    setCountdown(3);
    
    // Start countdown
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          const computerChoice = choices[Math.floor(Math.random() * 3)];
          setComputerChoice(computerChoice);
          setShowChoices(true);
          determineWinner(choice, computerChoice);
          return null;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const determineWinner = (user, computer) => {
    if (user === computer) {
      setResult("It's a tie!");
    } else if (
      (user === 'rock' && computer === 'scissors') ||
      (user === 'paper' && computer === 'rock') ||
      (user === 'scissors' && computer === 'paper')
    ) {
      setResult('You win!');
      setScore(prev => ({ ...prev, user: prev.user + 1 }));
    } else {
      setResult('Computer wins!');
      setScore(prev => ({ ...prev, computer: prev.computer + 1 }));
    }
    setShowPopup(true);
    // Auto-hide popup after 2 seconds
    setTimeout(() => setShowPopup(false), 2000);
  };

  const resetGame = () => {
    setUserChoice(null);
    setComputerChoice(null);
    setResult('');
    setScore({ user: 0, computer: 0 });
    setShowPopup(false);
  };

  return (
    <div className='rock-game-container anton-regular'>
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} alt="Exit" className="exit-png" />
      </button>
         <div className="rock-game">
      <div className="score-board">
        <div className="score-card user">
          <h2>You</h2>
          <span className="points">{score.user}</span>
        </div>
        <div className="score-card computer">
          <h2>Computer</h2>
          <span className="points">{score.computer}</span>
        </div>
      </div>

      <h1>Rock Paper Scissors</h1>
      
      {countdown && (
        <div className="countdown">
          <h2>{countdown}</h2>
        </div>
      )}

      <div className="choices">
        {choices.map((choice) => (
          <button 
            key={choice} 
            onClick={() => handleClick(choice)}
            disabled={countdown !== null}
          >
            {choice.toUpperCase()}
          </button>
        ))}
      </div>

      {showChoices && userChoice && computerChoice && (
        <div className="result anton-regular">
          <p className='user-choice anton-regular'>Your choice: {userChoice}</p>
          <p className='computer-choice anton-regular'>Computer's choice: {computerChoice}</p>
          <p className="winner anton-regular">{result}</p>
        </div>
      )}
      
      <button className="reset" onClick={resetGame}>Reset Game</button>
    </div>

    {showPopup && result && (
      <div className="popup-overlay anton-regular">
        <div className="popup">
          <h2>{result}</h2>
          {userChoice && computerChoice && (
            <div>
              <p>You chose: {userChoice}</p>
              <p>Computer chose: {computerChoice}</p>
            </div>
          )}
        </div>
      </div>
    )}

    </div>
  );
};

export default Rock;
