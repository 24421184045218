import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Exit from './assets/Exit.png'

const ShapeShooter = () => {
  const [shapes, setShapes] = useState([]);
  const [score, setScore] = useState(0);
  const [instruction, setInstruction] = useState("");
  const [isGameOver, setIsGameOver] = useState(false); // State to toggle Game Over modal
  const gameAreaRef = useRef(null);
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const shapeTypes = ["Circle", "Square", "Triangle"];

  useEffect(() => {
    generateShapes();
    pickInstruction();

    const animationFrame = requestAnimationFrame(updateShapes);
    return () => cancelAnimationFrame(animationFrame); // Cleanup on unmount
  }, []);

  useEffect(() => {
    // When shapes array is empty, regenerate shapes and instructions
    if (shapes.length === 0) {
      generateShapes();
      pickInstruction();
    }
  }, [shapes]);

  const generateShapes = () => {
    const newShapes = Array.from({ length: 20 }, () => {
      const type = shapeTypes[Math.floor(Math.random() * shapeTypes.length)];
      return {
        id: Math.random(),
        type,
        top: Math.random() * 80 + 10,
        left: Math.random() * 80 + 10,
        speedX: (Math.random() - 0.5) * 2, // Horizontal speed
        speedY: (Math.random() - 0.5) * 2, // Vertical speed
      };
    });
    setShapes(newShapes);
  };

  const pickInstruction = () => {
    const type = shapeTypes[Math.floor(Math.random() * shapeTypes.length)];
    setInstruction(`Shoot all ${type}s!`);
  };

  const updateShapes = () => {
    setShapes((prevShapes) =>
      prevShapes.map((shape) => {
        let { top, left, speedX, speedY } = shape;

        // Boundary collision
        const gameArea = gameAreaRef.current?.getBoundingClientRect();
        if (!gameArea) return shape;

        if (left < 0 || left + 40 > gameArea.width) speedX = -speedX;
        if (top < 0 || top + 40 > gameArea.height) speedY = -speedY;

        return {
          ...shape,
          top: top + speedY,
          left: left + speedX,
          speedX,
          speedY,
        };
      })
    );

    // Continue animation
    requestAnimationFrame(updateShapes);
  };

  const handleShapeClick = (id, type) => {
    if (instruction.includes(type)) {
      setShapes((prevShapes) => prevShapes.filter((shape) => shape.id !== id));
      setScore(score + 1);
    } else {
      setIsGameOver(true); // Show Game Over popup
    }
  };

  const restartGame = () => {
    generateShapes();
    pickInstruction();
    setScore(0);
    setIsGameOver(false); // Hide Game Over popup
  };

  return (
    <div
      style={{
        textAlign: "center",
        position: "relative",
        height: "100vh",
        background: "#1e1e2e",
        color: "#fff",
        overflow: "hidden",
      }}
    >
        <div style={{
            display:"flex",
            justifyContent:"flex-start",
            alignContent:"flex-start"
        }}>
        <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png'/>
      </button>
        </div>
      
      <h1>Shape Shooter</h1>
      <h3>{instruction}</h3>
      <h4>Score: {score}</h4>

      {/* Game Area */}
      <div
        ref={gameAreaRef}
        style={{
          position: "relative",
          height: "70vh",
          margin: "0 auto",
          width: "100%",
          border: "2px solid #eee",
          borderRadius: "8px",
          overflow: "hidden",
          background: "#2e3440",
        }}
      >
        {shapes.map((shape) => (
          <div
            key={shape.id}
            onClick={() => handleShapeClick(shape.id, shape.type)}
            style={{
              position: "absolute",
              top: `${shape.top}px`,
              left: `${shape.left}px`,
              width: "40px",
              height: "40px",
              backgroundColor:
                shape.type === "Circle"
                  ? "red"
                  : shape.type === "Square"
                  ? "blue"
                  : "green",
              borderRadius: shape.type === "Circle" ? "50%" : "0",
              clipPath: shape.type === "Triangle" ? "polygon(50% 0, 0 100%, 100% 100%)" : "none",
              transition: "transform 0.1s",
              cursor: "pointer",
              boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            }}
          />
        ))}
      </div>

      <button className="btn btn-primary mt-3" onClick={restartGame}>
        Restart Game
      </button>

      {/* Game Over Modal */}
      {isGameOver && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.7)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "10px",
              textAlign: "center",
              color: "#1e1e2e",
            }}
          >
            <h2>Game Over</h2>
            <p>Your final score is: {score}</p>
            <button className="btn btn-primary" onClick={restartGame}>
              Restart
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShapeShooter;
