// App.js
import React from 'react';
import Navbar from './Navbar';
import Home from './Home';
import Games from './Games';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Setting from './Setting';
import DailyRewardsPage from './DailyRewardsPage';
import RewardsPage from './RewardsPage';
import Quizuser from './Quizuser';
import Quizgame from './Quizgame';
import Quizuserfriend from './Quizuserfriend';
import Quizfriendgame from './Quizfriendgame';
import Quizuserbrother from './Quizuserbrother';
import Quizbrothergame from './Quizbrothergame';
import Quizusersister from './Quizusersister';
import Quizsistergame from './Quizsistergame';
import Quizusermother from './Quizusermother';
import Quizuserfather from './Quizuserfather';
import Quizmothergame from './Quizmothergame';
import Quizfathergame from './Quizfathergame';
import TapNumbers from './TapNumbers';
import Tictactoe from './Tictactoe';
import TelegramHeaderColor from './TelegramHeaderColor';
import Tapgame from './Tapgame';
import Dice from './Dice';
import MemoryGame from './MemoryGame';
import ColorMatch from './Colormatch';
import ShapeShooter from './ShapeShooter';
import WordScramble from './WordScramble';
import EmojiHuntGame from './EmojiHunt';
import NumberGuessingGame from './NumberGuessing';
import Rock from './Rock';
import Game2048 from './Calculate'
import WhackAMole from './Mole'
import MineSweeper from './MineSweeper'



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/games" element={<Games />} />
        <Route path="/settings" element={<Setting/>}/>
        <Route path="/dailyrewards" element={<DailyRewardsPage/>}/>
        <Route path="/rewards" element={<RewardsPage/>}/>
        <Route path="/bestfriend" element={<Quizuser/>}/>
        <Route path="/friend" element={<Quizuserfriend/>}/>
        <Route path="/brother" element={<Quizuserbrother/>}/>
        <Route path="/sister" element={<Quizusersister/>}/>
        <Route path="/mother" element={<Quizusermother/>}/>
        <Route path="/father" element={<Quizuserfather/>}/>
        <Route path="/game" element={<Quizgame/>}/>
        <Route path="/friendquiz" element={<Quizfriendgame/>}/>
        <Route path="/brotherquiz" element={<Quizbrothergame/>}/>
        <Route path="/sisterquiz" element={<Quizsistergame/>}/>
        <Route path="/motherquiz" element={<Quizmothergame/>}/>
        <Route path="/fatherquiz" element={<Quizfathergame/>}/>
        <Route path="/tapnumbergame" element={<TapNumbers/>}/>
        <Route path="/tictactoe" element={<Tictactoe/>}/>
        <Route path="/tapgame" element={<Tapgame/>}/>
        <Route path="/dice" element={<Dice/>}/>
        <Route path="/memory" element={<MemoryGame/>}/>
        <Route path="/shooter" element={<ShapeShooter/>}/>
        <Route path="/colormatch" element={<ColorMatch/>}/>
        <Route path="/scramble" element={<WordScramble/>}/>
        <Route path="/numberguess" element={<NumberGuessingGame/>}/>
        <Route path="/emojihunt" element={<EmojiHuntGame/>}/>
        <Route path="/rock" element={<Rock/>}/>
        <Route path="/calculate" element={<Game2048/>}/>
        <Route path="/mole" element={<WhackAMole/>}/>
        <Route path="/mine" element={<MineSweeper/>}/>
        <Route path="/head" element={<TelegramHeaderColor/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;