import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Exit from './assets/Exit.png'
import Diceicon from "./assets/diceicon.png";
import './dice.css';

const Dice = () => {
  const [yourScore, setYourScore] = useState(0);
  const [systemScore, setSystemScore] = useState(0);
  const [currentRoll, setCurrentRoll] = useState(null);
  const [turnsLeft, setTurnsLeft] = useState(6);
  const [popupMessage, setPopupMessage] = useState("");
  const [rolling, setRolling] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const rollDice = () => {
    if (rolling || gameOver) return;

    setRolling(true);
    const roll = Math.floor(Math.random() * 6) + 1;

    setTimeout(() => {
      setCurrentRoll(roll);
      setPopupMessage(`You scored ${roll}!`);

      setTimeout(() => {
        setPopupMessage(""); // Hide popup after 1 second
        setYourScore((prev) => prev + roll);
        setTurnsLeft((prev) => prev - 1);

        if (turnsLeft - 1 === 0) {
          setGameOver(true);
        } else {
          setTimeout(autoPlaySystem, 1000); // System plays after 1 second delay
        }

        setRolling(false);
      }, 1000);
    }, 1000); // Simulate dice roll animation delay
  };

  const autoPlaySystem = () => {
    const roll = Math.floor(Math.random() * 6) + 1;

    setPopupMessage(`System scored ${roll}!`);

    setTimeout(() => {
      setPopupMessage(""); // Hide popup after 1 second
      setSystemScore((prev) => prev + roll);

      if (turnsLeft === 1) {
        setGameOver(true);
      }
    }, 1000);
  };

  const resetGame = () => {
    setYourScore(0);
    setSystemScore(0);
    setCurrentRoll(null);
    setTurnsLeft(6);
    setGameOver(false);
    setPopupMessage("");
  };

  const winner =
    turnsLeft === 0
      ? yourScore > systemScore
        ? "You Win!"
        : systemScore > yourScore
        ? "System Wins!"
        : "It's a Tie!"
      : null;

  const styles = {
    container: {
      textAlign: "center",
      padding: "20px",
      fontFamily: "'Roboto', sans-serif",
      backgroundColor: "#242037",
      minHeight: "100vh",
    },
    heading: {
      fontSize: "36px",
      color: "white",
      marginBottom: "20px",
    },
    diceContainer: {
      margin: "20px auto",
      height: "100px",
      width: "100px",
    },
    diceImage: {
      width: "100%",
      animation: rolling ? "spin 1s infinite linear" : "none",
    },
    button: {
      padding: "15px 30px",
      fontSize: "18px",
      backgroundColor: rolling || gameOver ? "#CCC" : "#28A745",
      color: "white",
      border: "none",
      borderRadius: "8px",
      cursor: rolling || gameOver ? "not-allowed" : "pointer",
      margin: "10px",
      transition: "transform 0.2s",
    },
    scoreCard: {
      display: "inline-block",
      width: "200px",
      margin: "20px",
      padding: "15px",
      borderRadius: "10px",
      boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
      backgroundColor: "white",
    },
    popup: {
      position: "absolute",
      top: "20%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#FFD700",
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      zIndex: 1000,
      fontSize: "20px",
      color: "#333",
    },
  };

  return (
    <div style={styles.container}>
      <div className="exit-container">
      <button className="exit-button" onClick={() => navigate('/games')}>
        <img src={Exit} className='exit-png'/>
      </button>
      </div>
      <h1 style={styles.heading}>Dice Game</h1>
      <div style={styles.diceContainer}>
        <img
          src={Diceicon}
          alt="Dice"
          style={styles.diceImage}
        />
      </div>
      {!gameOver && (
        <div>
          <h2 style={{ fontSize: "24px", marginBottom: "20px", color: "white" }}>
            Your Turn
          </h2>
          <button
            style={styles.button}
            onClick={rollDice}
            disabled={rolling || gameOver}
          >
            Roll Dice
          </button>
          {currentRoll !== null && (
            <p style={{ fontSize: "20px", color: "#555", marginTop: "15px" }}>
              Last Roll: {currentRoll}
            </p>
          )}
          <p style={{ fontSize: "18px", marginTop: "15px", color: "red" }}>
            Turns Left: {turnsLeft}
          </p>
        </div>
      )}
      {popupMessage && <div style={styles.popup}>{popupMessage}</div>}
      <div>
        <div style={styles.scoreCard}>
          <h3 style={{ fontSize: "24px", color: "#28A745" }}>You</h3>
          <p style={{ fontSize: "22px" }}>{yourScore}</p>
        </div>
        <div style={styles.scoreCard}>
          <h3 style={{ fontSize: "24px", color: "#28A745" }}>System</h3>
          <p style={{ fontSize: "22px" }}>{systemScore}</p>
        </div>
      </div>
      {gameOver && winner && (
        <div>
          <h2 style={{ fontSize: "28px", color: "#007BFF" }}>{winner}</h2>
          <button
            style={styles.button}
            onClick={resetGame}
            onMouseOver={(e) => (e.target.style.transform = "scale(1.1)")}
            onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
          >
            Reset Game
          </button>
        </div>
      )}
    </div>
  );
};


export default Dice;
